<div class="text-center">
    <div class="page-title">
        <h1>Sweepstakes Photo Gallery</h1>
    </div>
</div>
<div id="main-content">
    <div id="team-photos">
        <div class="col-md-4" *ngFor="let photo of photos">
            <a href="{{photo.url}}" data-pswp-width="1200" data-pswp-height="600">
                <img src="{{photo.url}}" alt="" />
            </a>
        </div>
    </div>
</div>