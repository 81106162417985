<mat-toolbar style="font-size: initial;">
    <div fxHide.gt-xs>
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div>
        <a routerLink="">
            <img width="160" alt="SFX Football Logo" src="../assets/images/SFX-Football-Logo.png" />
        </a>
    </div>
    <div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>
        <ul fxLayout fxLayoutGap="15px" class="navigation-items">
            <li>
                <a href="https://sfxcrossplains.org/" target="_blank">About SFX</a>
            </li>
            <li>
                <a routerLink="/calendar">Calendar</a>
            </li>
            <li>
                <a routerLink="/photos">Photos</a>
            </li>
            <li>
                <a routerLink="/sweepstakes">Sweepstakes</a>
            </li>
            <li>
                <a href="../../../assets/athleticform.pdf" target="_blank">Physical Examination Card</a>
            </li>
            <mat-list-item [matMenuTriggerFor]="menu">
                <a matline>Additional Links</a>
            </mat-list-item>
            <mat-menu #menu="matMenu">
                <button mat-menu-item>
                    <a href="https://usafootball.com/programs/heads-up-football/" target="_blank">
                        USA Football
                    </a>
                </button>
                <button mat-menu-item>
                    <a href="https://www.nays.org/" target="_blank">
                        National Alliance for Youth Sports
                    </a>
                </button>
                <button mat-menu-item>
                    <a href="https://www.facebook.com/sfxfootball/" target="_blank">
                        SFX Football Facebook
                    </a>
                </button>
                <button mat-menu-item>
                    <a href="https://www.sfxcatholicschool.org/" target="_blank">
                        St. Francis Xavier School
                    </a>
                </button>
            </mat-menu>
        </ul>
    </div>
</mat-toolbar>