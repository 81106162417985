<mat-nav-list>
    <a mat-list-item routerLink="/" (click)="onSidenavClose()">
        <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
    </a>
    <a mat-list-item href="https://sfxcrossplains.org/" target="_blank">About SFX</a>
    <a mat-list-item routerLink="/calendar" (click)="onSidenavClose()">
        <span class="nav-caption">Calendar</span>   
    </a>
    <a mat-list-item routerLink="/photos" (click)="onSidenavClose()">
        <span class="nav-caption">Photos</span>
    </a>
    <a mat-list-item routerLink="/sweepstakes" (click)="onSidenavClose()">
        <span class="nav-caption">Sweepstakes</span>
    </a>
    <a mat-list-item href="../../../assets/athleticform.pdf" target="_blank">Physical Examination Card</a>
    <mat-list-item [matMenuTriggerFor]="menu">
        <mat-icon>unfold_more</mat-icon>
        <a matline>Additional Links</a>
    </mat-list-item>
    <mat-menu #menu="matMenu">
        <button mat-menu-item>
            <a href="https://usafootball.com/programs/heads-up-football/" target="_blank">
                USA Football
            </a>
        </button>
        <button mat-menu-item>
            <a href="https://www.nays.org/" target="_blank">
                National Alliance for Youth Sports
            </a>
        </button>
        <button mat-menu-item>
            <a href="https://www.facebook.com/sfxfootball/" target="_blank">
                SFX Football Facebook
            </a>
        </button>
        <button mat-menu-item>
            <a href="https://www.sfxcatholicschool.org/" target="_blank">
                St. Francis Xavier School
            </a>
        </button>
    </mat-menu>
</mat-nav-list>
