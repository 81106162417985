import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import {CalendarComponent} from '../calendar/calendar.component';
import {PhotosComponent} from '../photos/photos.component';
import {SweepstakesComponent} from '../sweepstakes/sweepstakes.component';
import { SweepstakesGalleryComponent } from '../sweepstakes-gallery/sweepstakes-gallery.component'; 
const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'calendar', component: CalendarComponent},
  { path: 'photos', component: PhotosComponent},
  { path: 'sweepstakes', component: SweepstakesComponent},
  { path: 'sweepstakes-gallery', component: SweepstakesGalleryComponent}
 
];
 
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class RoutingModule { }
