<div class="text-center">
    <div class="page-title">
        <h1>Photos</h1>
    </div>
</div>
<div id="main-content">
    <div id="team-photos">
        <a href="../../assets/images/photos/gb-ff-quarterback-1080x1347.jpg" data-pswp-width="1200" data-pswp-height="600">
            <img src="../../assets/images/photos/gb-ff-quarterback-300x400.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/gb-ff-field-play-1080x1229.jpg" data-pswp-width="1200" data-pswp-height="1200">
            <img src="../../assets/images/photos/gb-ff-field-play-600x400.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/gb-ff-catch-1080x1347.jpg" data-pswp-width="600" data-pswp-height="1200">
            <img src="~/images/photos/gb-ff-catch-300x400.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/flag-football-billboard-1200.jpg" data-pswp-width="600" data-pswp-height="1200">
            <img src="../../assets/images/photos/flag-football-billboard-1200.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/gb-ff-field-play-2-1080x1347.jpg" data-pswp-width="1200" data-pswp-height="600">
            <img src="../../assets/images/photos/gb-ff-field-play-2-450x400.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/gb-ff-team-1080x1347.jpg" data-pswp-width="1200" data-pswp-height="1200">
            <img src="../../assets/images/photos/gb-ff-team-300x400.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/gb-ff-field-play-3-1080x1537.jpg" data-pswp-width="600" data-pswp-height="1200">
            <img src="../../assets/images/photos/gb-ff-field-play-3-450x400.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/5th-grade-team-2023.jpg" data-pswp-width="600" data-pswp-height="1200">
            <img src="../../assets/images/photos/5th-grade-team-2023-1200.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/cecil-martin-camp-1.jpg" data-pswp-width="1200" data-pswp-height="600">
            <img src="../../assets/images/photos/cecil-martin-camp-1-600.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/sfx-football-field-1200.jpg" data-pswp-width="1200" data-pswp-height="1200">
            <img src="../../assets/images/photos/sfx-football-field-600.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/green-bay-lambeau-field-1200.jpg" data-pswp-width="600" data-pswp-height="1200">
            <img src="../../assets/images/photos/green-bay-lambeau-field-1200.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/YLW_7486.jpg" data-pswp-width="1200" data-pswp-height="600">
            <img src="../../assets/images/photos/YLW_7486-600.jpg" alt="" />
        </a>
        <a href="../../assets/images/photos/YLW_7571.jpg" data-pswp-width="1200" data-pswp-height="1200">
            <img src="../../assets/images/photos/YLW_7571-600.jpg" alt="" />
        </a>
    </div>
</div>

