import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-sweepstakes-gallery',
  templateUrl: './sweepstakes-gallery.component.html',
  styleUrls: ['./sweepstakes-gallery.component.css']
})
export class SweepstakesGalleryComponent implements OnInit {
  displayedColumns = ['position', 'name', 'weight', 'symbol'];
  //dataSource = new MatTableDataSource(ELEMENT_DATA);
  photos = [
    {url: '../../assets/sweepstake-gallery/1.jpg'},
    {url: '../../assets/sweepstake-gallery/2.jpg'},
    {url: '../../assets/sweepstake-gallery/3.jpg'},
    {url: '../../assets/sweepstake-gallery/4.jpg'},
    {url: '../../assets/sweepstake-gallery/5.jpg'},
    {url: '../../assets/sweepstake-gallery/6.jpg'},
    {url: '../../assets/sweepstake-gallery/7.jpg'},
    {url: '../../assets/sweepstake-gallery/8.jpg'},
    {url: '../../assets/sweepstake-gallery/9.jpg'},
    {url: '../../assets/sweepstake-gallery/10.jpg'},
    {url: '../../assets/sweepstake-gallery/11.jpg'},
    {url: '../../assets/sweepstake-gallery/12.jpg'},
    {url: '../../assets/sweepstake-gallery/13.jpg'},
    {url: '../../assets/sweepstake-gallery/14.jpg'},
    {url: '../../assets/sweepstake-gallery/15.jpg'},
    {url: '../../assets/sweepstake-gallery/16.jpg'},
    {url: '../../assets/sweepstake-gallery/17.jpg'},
    {url: '../../assets/sweepstake-gallery/18.jpg'},
    {url: '../../assets/sweepstake-gallery/19.jpg'},
    {url: '../../assets/sweepstake-gallery/20.jpg'},
    {url: '../../assets/sweepstake-gallery/21.jpg'},
    {url: '../../assets/sweepstake-gallery/22.jpg'},
    {url: '../../assets/sweepstake-gallery/23.jpg'},
    {url: '../../assets/sweepstake-gallery/24.jpg'},
    {url: '../../assets/sweepstake-gallery/25.jpg'},
    {url: '../../assets/sweepstake-gallery/26.jpg'},
    {url: '../../assets/sweepstake-gallery/27.jpg'},
    {url: '../../assets/sweepstake-gallery/28.jpg'},
    {url: '../../assets/sweepstake-gallery/29.jpg'},
    {url: '../../assets/sweepstake-gallery/30.jpg'},
    {url: '../../assets/sweepstake-gallery/31.jpg'},
    {url: '../../assets/sweepstake-gallery/32.jpg'},
    {url: '../../assets/sweepstake-gallery/33.jpg'},
    {url: '../../assets/sweepstake-gallery/34.jpg'},
    {url: '../../assets/sweepstake-gallery/35.jpg'},
    {url: '../../assets/sweepstake-gallery/36.jpg'},
    {url: '../../assets/sweepstake-gallery/37.jpg'},
    {url: '../../assets/sweepstake-gallery/38.jpg'},
    {url: '../../assets/sweepstake-gallery/39.jpg'},
    {url: '../../assets/sweepstake-gallery/40.jpg'},
    {url: '../../assets/sweepstake-gallery/41.jpg'},
    {url: '../../assets/sweepstake-gallery/42.jpg'},
    {url: '../../assets/sweepstake-gallery/43.jpg'},
    {url: '../../assets/sweepstake-gallery/44.jpg'},
    {url: '../../assets/sweepstake-gallery/45.jpg'},
    {url: '../../assets/sweepstake-gallery/46.jpg'},
    {url: '../../assets/sweepstake-gallery/47.jpg'},
    {url: '../../assets/sweepstake-gallery/48.jpg'},
    {url: '../../assets/sweepstake-gallery/49.jpg'},
    {url: '../../assets/sweepstake-gallery/50.jpg'},
    {url: '../../assets/sweepstake-gallery/51.jpg'},
    {url: '../../assets/sweepstake-gallery/52.jpg'},
    {url: '../../assets/sweepstake-gallery/53.jpg'},
    {url: '../../assets/sweepstake-gallery/54.jpg'},
    {url: '../../assets/sweepstake-gallery/55.jpg'},
    {url: '../../assets/sweepstake-gallery/56.jpg'},
    {url: '../../assets/sweepstake-gallery/57.jpg'},
    {url: '../../assets/sweepstake-gallery/58.jpg'},
    {url: '../../assets/sweepstake-gallery/59.jpg'},
    {url: '../../assets/sweepstake-gallery/60.jpg'},
    {url: '../../assets/sweepstake-gallery/61.jpg'},
    {url: '../../assets/sweepstake-gallery/62.jpg'},
    {url: '../../assets/sweepstake-gallery/63.jpg'},
    {url: '../../assets/sweepstake-gallery/64.jpg'},
    {url: '../../assets/sweepstake-gallery/65.jpg'},
    {url: '../../assets/sweepstake-gallery/66.jpg'},
    {url: '../../assets/sweepstake-gallery/67.jpg'},
    {url: '../../assets/sweepstake-gallery/68.jpg'},
    {url: '../../assets/sweepstake-gallery/69.jpg'},
    {url: '../../assets/sweepstake-gallery/70.jpg'},
    {url: '../../assets/sweepstake-gallery/70.jpg'},
    {url: '../../assets/sweepstake-gallery/71.jpg'},
    {url: '../../assets/sweepstake-gallery/72.jpg'},
    {url: '../../assets/sweepstake-gallery/73.jpg'},
    {url: '../../assets/sweepstake-gallery/74.jpg'},
    {url: '../../assets/sweepstake-gallery/75.jpg'},
    {url: '../../assets/sweepstake-gallery/76.jpg'},
    {url: '../../assets/sweepstake-gallery/77.jpg'},
    {url: '../../assets/sweepstake-gallery/78.jpg'},
    {url: '../../assets/sweepstake-gallery/79.jpg'}
  ]


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
   // this.dataSource.filter = filterValue;
    
  }
  constructor() { }

  ngOnInit(): void {
  }

}
export interface Element {
  url: string;
}

